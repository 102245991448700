import React, { useState, useEffect } from 'react'
import { useStore, useSetStoreValue } from 'react-context-hook'
import PropTypes from 'prop-types'
import codes from 'http-status-codes'
import lodash from 'lodash'
import { guid } from '@progress/kendo-react-common'
import useCookie from '/src/hooks/cookie'
import I18n from '/src/utils/translations'
import KendoFileStatus from '/src/utils/kendo_file_status'
import { byString } from '/src/utils/object'
import { merge, remove } from '/src/utils/array'
import {
  MAX_PICTURE_SIZE,
  MAX_ALLOWED_NUM_PICTURES,
  PICTURE_CATEGORY_ID,
  formTypes
} from '/src/models/concerns/attachment'
import ImageModal from '/src/ui/core/popups/image_modal'
import ImageThumbnail from '/src/ui/core/inputs/image_thumbnail/image_thumbnail'
import CommentSection from '/src/ui/core/inputs/image_thumbnail/comment_section'
import UploadAttachmnetModal from '/src/ui/core/inputs/attachments/upload_attachment_modal'
import IconButtonContainer from '/src/ui/core/layouts/icon_button_container'
import useInputError from '/src/ui/core/inputs/input_error'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import '/src/static/css/core/inputs/input_attachment.css'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

export default function InputPicture({ inputProps }) {
  const {
    id,
    allowedExtensions,
    maxFileSize,
    readOnly,
    title,
    required,
    onChange,
    dataItem,
    columnId,
    className
  } = inputProps

  const [modalOpened, setModalOpened] = useState(false)
  const [fullScreenOpen, setFullScreenOpen] = useState(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [globalProject] = useStore('project')
  const [globalSubproject] = useStore('subproject')
  const [getToken] = useCookie('remember_token')
  const [innerFiles, setInnerFiles] = useState([])
  const setNotification = useSetStoreValue('notification')
  const isCreationForm = formTypes(dataItem) === 'new'
  const { uniqBy } = lodash

  const creationSaveAction = 'pictures.json'
  const updateSaveAction = 'upload_photo'
  const fileColumnType = 'picture'

  const filesUuids = (item) => {
    if (!item || !item[id]) return []
    return item[id]
  }

  const [uuids, setUuids] = useState(filesUuids(dataItem))

  const initFiles = () => {
    const columnUuids = filesUuids(dataItem)
    if (columnUuids.length === 0) return []
    const itemFiles = dataItem.pictures
    const columnFiles = itemFiles?.filter((at) => dataItem[id].includes(at.uuid))
    const kendoFiles = []

    if (columnFiles) {
      columnFiles.forEach((file) => {
        const fileName = file.photo_file_name
        const fileSize = file.photo_file_size

        kendoFiles.push({
          getRawFile: () => file,
          name: fileName,
          size: fileSize,
          file_category_id: file.file_category_id || PICTURE_CATEGORY_ID,
          uid: file.uuid,
          uuid: file.uuid,
          status: KendoFileStatus.uploaded,
          progress: 100
        })
      })
    }

    return kendoFiles
  }

  const [files, setFiles] = useState(initFiles())
  const anyFileUploaded = !!files.length
  const error = useInputError({ inputValue: files, title, required, type: 'array' })

  useEffect(() => {
    onChange(id, uuids)
  }, [id, onChange, uuids])

  let params = `?eav_column_id=${columnId}`
  params += `&project_id=${globalProject.id}&subproject_id=${globalSubproject.id}`

  const createUrl = (path) => {
    return `${SERVER_URL}/api/v1/${path}${params}`
  }

  const updateUrl = (action) => {
    return `${SERVER_URL}/api/v1/${dataItem.route}/${dataItem.id}/${action}.json${params}`
  }

  const updateFiles = (event) => {
    setInnerFiles(event.newState)
    if (!event.response) return

    if (event.response.status === codes.CREATED) {
      const newUuid = event.response.response.uuid
      setUuids((oldUuids) => merge(oldUuids, [newUuid]))
      dispatch(BusEvents.RELOAD_GRID)
      return
    }

    if (event.response.status === codes.NO_CONTENT) {
      const removedUuids = event.affectedFiles.map((file) => file.uuid)
      setUuids((oldUuids) => remove(oldUuids, removedUuids))
    }
  }

  const message = (scenario, status, body) => ({
    title: I18n.t(`form.inputs.attachment.${scenario}_${status}`),
    body,
    status,
    closable: true,
    closeTimeout: 10
  })

  const onStatusChange = (event) => {
    const showUploadErrorMsg = () => {
      const responseData = byString(event.response, 'response.data')
      if (responseData && responseData.error) {
        setNotification(message('upload', 'error', event.response.response.data.error))
        return
      }
      if (responseData && responseData.photo) {
        let msgBody = byString(event.response, 'response.data.photo')
        msgBody = msgBody ? msgBody.join('; ') : undefined
        setNotification(message('upload', 'error', msgBody))
        return
      }
      setNotification(message('upload', 'error', undefined))
    }

    updateFiles(event)

    if (!event.response) return

    if (event.response.status === codes.CREATED) {
      const affectedIndex = event.newState.findIndex((file) => {
        return file.uid === event.affectedFiles[0].uid
      })
      event.newState[affectedIndex].uuid = event.response.response.uuid
      event.newState[affectedIndex].file_category_id = event.response.response.file_category_id || PICTURE_CATEGORY_ID

      updateFiles(event)
      setNotification(message('upload', 'success'))
      return
    }

    showUploadErrorMsg()
  }

  const settings = {
    id: `attachment-kendo-upload-${id}`,
    selectText: I18n.t('form.inputs.attachment.select_files'),
    onFiles: () => innerFiles,
    onBeforeUpload: (event) => {
      event.additionalData.uuid = guid()
      event.headers.authorization = `Token ${getToken()}`
    },
    withCredentials: true,
    multiple: true,
    saveField: isCreationForm ? fileColumnType : id,
    saveUrl: isCreationForm ? createUrl(creationSaveAction) : updateUrl(updateSaveAction),
    onAdd: updateFiles,
    onProgress: updateFiles,
    onRemove: updateFiles,
    onStatusChange,
    restrictions: { allowedExtensions, maxFileSize }
  }

  useEffect(() => {
    if (innerFiles.length && innerFiles.every((e) => e.status === KendoFileStatus.uploaded)) {
      setModalOpened(false)
      setFiles((prevFiles) => uniqBy([...prevFiles, ...innerFiles], 'uuid'))

      setInnerFiles([])
    }
  }, [innerFiles, uniqBy])

  const openImportModal = () => {
    if (files.length >= MAX_ALLOWED_NUM_PICTURES) {
      const messageBody = I18n.t('form.inputs.attachment.maximum_num_uploads_error', {
        max_num: MAX_ALLOWED_NUM_PICTURES
      })
      setNotification(message('add', 'error', messageBody))
      return
    }

    setModalOpened(true)
  }

  const openImageFullScreen = (index) => {
    setSelectedImageIndex(index)
    setFullScreenOpen(true)
  }

  const closeImageFullScreen = () => {
    setFullScreenOpen(false)
  }

  const onDelete = (attachment) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.uuid !== attachment.uuid))
    onChange(id, null)
  }

  const getImagesWithComments = (files) => {
    return files.map((file) => {
      const pictures = dataItem?.pictures || []
      const comment = Object.values(pictures).find((pic) => pic.uuid === file.uuid)?.comment
      const image = {
        ...file,
        file_path: `/api/v1/pictures/${file.uuid}`,
        comment,
        imageable_id: dataItem?.id,
        imageable_type: dataItem?.pictures?.[0]?.imageable_type
      }
      return { image, comment }
    })
  }

  const renderUploadedFiles = () => {
    const imagesWithComments = getImagesWithComments(files)
    return (
      <React.Fragment>
        {imagesWithComments.map(({ image }, index) => (
          <ImageThumbnail
            key={`${image.uuid}-${index}`}
            image={image}
            onDelete={onDelete}
            columnId={columnId}
            onClick={() => openImageFullScreen(index)}
          >
            <CommentSection image={image} />
          </ImageThumbnail>
        ))}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="import-dropdown import-picture">
        <IconButtonContainer id={id} error={error != undefined} readOnly={readOnly} onClickIcon={openImportModal}>
          {modalOpened && (
            <UploadAttachmnetModal
              innerFiles={innerFiles}
              allowedExtensions={allowedExtensions}
              maxSize={MAX_PICTURE_SIZE}
              settings={settings}
              closeModal={() => setModalOpened(false)}
              className={className}
            />
          )}
        </IconButtonContainer>
        {anyFileUploaded && renderUploadedFiles()}
        {fullScreenOpen && (
          <ImageModal
            images={getImagesWithComments(files).map(({ image }) => ({
              file_path: image.file_path,
              comment: image.comment
            }))}
            extraClass={'full-screen form-image'}
            openImage={selectedImageIndex}
            onClose={closeImageFullScreen}
          />
        )}
      </div>
      <div className="error-label error-label-attachment">{error}</div>
    </React.Fragment>
  )
}

InputPicture.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.array,
    dataItem: PropTypes.oneOfType([PropTypes.object]),
    columnId: PropTypes.number,
    readOnly: PropTypes.bool,
    allowedExtensions: PropTypes.oneOfType([PropTypes.array]),
    maxFileSize: PropTypes.number,
    onChange: PropTypes.func,
    isPictureInput: PropTypes.bool,
    className: PropTypes.string,
    title: PropTypes.string,
    required: PropTypes.bool
  }).isRequired
}
